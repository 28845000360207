<template>
 <div :class="windowClass">
   <v-container>
    <v-row>
      <v-col cols="12">
                <v-card>
               <v-toolbar>
                 <v-icon left color="primary">mdi-account-multiple-check</v-icon> CLAIMS Per Distributor
                 <v-spacer/>
                 <span style="width: 200px; margin-left: 5px;">
                  <v-autocomplete
                  v-model="user"
                  :items="users"
                  
                  hide-details
                  item-text="fullname"
                  return-object
                  @change="event_id=0, voters=[]"
                  outlined
                    dense
                  label="Select User"
                  ></v-autocomplete>
                 </span>
                 <span style="width: 100px; margin-left: 5px;">
                  <v-autocomplete
                  v-model="event_id"
                  :items="events"
                  item-value="id"
                  item-text="name"
                  @change="getClaims()"
                  hide-details
                  outlined
                    dense
                  label="Select Event"
                  ></v-autocomplete>
                 </span>
                
               </v-toolbar>
               <v-card-text>
                <span class="text-success font-weight-bold text-h6">EVENT: {{event_id}}</span>
                <span class="text-success font-weight-bold ml-4 text-h6">CLAIMS: {{voters.length}}</span>
                <v-data-table
                    class="va-table"
                    dense
                    :headers="headers"
                    :search="search"
                    :items="byPrecinct"
                    >
                    <template v-slot:body="{ items }">
                        <tbody>
                        <tr v-for="(item,index) in items" :key="index">
                            <td>{{ item.prec }}</td>
                            <td>{{ item.fullname }}</td>
                            <td>{{item.claimed_by}}</td>
                            <td>{{$moment(item.claimed_dt).format("YYYY-MM-DD HH:mm A")}}</td>
                            <!-- <td>{{ item.role }}</td>
                             <td :class="STATUS_COLOR(item.status)">{{ item.status }}</td>
                            <td><v-icon left small :color="statusIndicator(item.last_login)">mdi-checkbox-blank-circle</v-icon> {{ $moment(item.last_login).fromNow()}}</td> -->
                        </tr>
                        </tbody>
                    </template>
                    </v-data-table>
               </v-card-text>
             </v-card>
          </v-col>
         
    </v-row>
    </v-container>
   
  </div>
</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    data: () => ({
        form: false,
        event_id: 1,
        trans_dailog: false,
        areaform: false,
        state: 'new',
        user: {},
        events: [],
        search:'',
        areas:[],
        user_id: 0,
        users:[],
        area:{},
        voter:{},
        voters:[],
        precinct:'ALL',
       
        headers: [
          { text: 'Prec', value:"prec"},
            { text: 'Name', value:"fullname"},
             { text: 'Claimed By', value:"claimed_by"},
             { text: 'ClaimedDT', value:"claimed_dt"}
        ]
    }),
    beforeCreate: function () {
      if (!this.$session.exists()) {
        this.$router.push('/')
      }
    },
    created() {
       if (this.$session.exists()) {
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.$session.get('jwt')
          this.setLoggedIn(true) 
          this.getUsers()
          this.getEvents()
         
        }
    },
    computed:{
       windowClass() {
        if(this.$store.state.responsive){
          return "va-reponsive"
        } else {
          return "va-not-reponsive"
        }
      },
      byPrecinct() {
        if(this.precinct == "ALL") {
          return  this.voters
        }
        var voters = this.voters.reduce((res,item)=>{
          if(item.prec == this.precinct) {
            res.push(item)
          }
          return res
        },[])

        return voters
      }
    },
    methods: {
      ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn']),
      transEvent(){
        this.trans_dailog = false
      },
      statusIndicator(_time) {
          //var current = this.$moment(_time)
          var a = this.$moment()
            var b = this.$moment(_time)
           var diff = a.diff(b, 'hour')
           if(diff <=  3) {
              return 'success'
           } else if(diff > 3 && diff < 5) {
               return '#A5D6A7'
           } else {
             return '#989898'
           }
      },
      CountBarangay(area){
          var _count = area.reduce((res,item)=>{
                res += item.barangays.length
                return res
          },0) 
          return _count
      },
      afEvent() {
        this.areaform = false
        this.getAreas()
      },
      formEvent(data){
          this.form = false
          if(data.action != 'close') {
            console.log(data)
              this.voter = data.voter
              this.Save()
          }
      },

      getEvents: function() { 
        this.$http.get("event/list").then(response => {
            response.data.items != null?this.events = response.data.items:this.events =[]
        }).catch(e => {
        console.log(e.data)
        })
     },
      getUsers: function() { 
        this.$http.get("user/list").then(response => {
            response.data.list != null?this.users = response.data.list:this.users =[]
        }).catch(e => {
        console.log(e.data)
        })
     },
      
      getClaims: function() { 
        this.voters = []
        console.log({user_id: this.user.id, event_id: this.event_id})
        this.$http.post("claims/per_user", {user_id: this.user.id, event_id: this.event_id}).then(response => {
          console.log(response.data)
            response.data.list != null?this.voters = response.data.list:this.voters =[]
        }).catch(e => {
        console.log(e.data)
        })
     },
    
     
    }
  }
</script>